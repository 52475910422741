import React, { useRef, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import "./sidebar.css";
import { useHistory } from "react-router-dom";

const Sidebar = ({ closeAction, user, logOut }) => {
    const wrapperRef = useRef(null);

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    closeAction();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    useOutsideAlerter(wrapperRef);
    const history = useHistory();

    return (
        <div className="sidebar">
            <div className="main-sidebar" ref={wrapperRef}>
                <div className="control-sidebar">
                    <button
                        onClick={() => closeAction()}
                        className="sidebar-close-button"
                    >
                        <FaTimes />
                    </button>
                </div>
                <div className="sidebar-menu">
                    <div
                        className="sidebar-menu-item"
                        onClick={() => history.push("/")}
                    >
                        Home
                    </div>
                    {!user?.user_id ? (
                        <>
                            <div
                                className="sidebar-menu-item"
                                onClick={() => history.push("/signin")}
                            >
                                Sign In
                            </div>
                            <div
                                className="sidebar-menu-item"
                                onClick={() => history.push("/signup")}
                            >
                                Sign Up
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className="sidebar-menu-item"
                                onClick={() => history.push("/bookmarks")}
                            >
                                Bookmarks
                            </div>
                            <div
                                className="sidebar-menu-item"
                                onClick={() => history.push("/purchases")}
                            >
                                Puchases
                            </div>
                        </>
                    )}

                    <div
                        className="sidebar-menu-item hidden-desktop"
                        onClick={() => history.push("/search")}
                    >
                        Search
                    </div>
                    <div
                        className="sidebar-menu-item"
                        onClick={() => history.push("/categories")}
                    >
                        Categories
                    </div>
                    <div
                        className="sidebar-menu-item"
                        onClick={() => history.push("/collections")}
                    >
                        Collections
                    </div>
                    {user?.user_id ? (
                        <div
                            className="sidebar-menu-item"
                            onClick={() => {
                                logOut();
                                closeAction();
                            }}
                        >
                            Log Out
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
