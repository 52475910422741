import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import expireIn from "redux-persist-transform-expire-in";
import storage from "redux-persist/lib/storage";
import userReducer, { INITIAL_STATE } from "./user/user.reducer";

const expire = 48 * 60 * 60 * 1000; // expire in 48h
const expirationKey = "kcf_persist_expiration_key";

const persistConfig = {
    key: "kcf_persist",
    storage,
    transforms: [
        expireIn(expire, expirationKey, {
            user: INITIAL_STATE,
        }),
    ],
};

const rootReducer = combineReducers({
    user: userReducer,
});

export default persistReducer(persistConfig, rootReducer);
