import React from "react";
import "./error-boundary.css";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../custom-button/custom-button";
import FeaturedBox from "../featured-box/featured-box";

class ErrorBoundary extends React.Component {
    constructor() {
        super();

        this.state = {
            hasErrored: false,
        };
    }

    static getDerivedStateFromError(err) {
        return {
            hasErrored: true,
        };
    }

    componentDidCatch(err, info) {
        console.log(err);
    }

    render() {
        return (
            <div>
                {this.state.hasErrored ? (
                    <FeaturedBox>
                        <Col md={12}>
                            <div
                                style={{
                                    minHeight: "200px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <h4 className="align-center">
                                    An Error Occured
                                </h4>
                            </div>
                            <Row>
                                <Col
                                    md={4}
                                    className="ms-auto me-auto centered"
                                >
                                    <CustomButton
                                        displayText="Refresh Page"
                                        onClick={() => window.location.reload()}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </FeaturedBox>
                ) : (
                    this.props.children
                )}
            </div>
        );
    }
}

export default ErrorBoundary;
