import React from "react";
import { Col, Row } from "react-bootstrap";
import FeaturedBox from "../../components/featured-box/featured-box";
import MyContainer from "../../components/mycontainer/mycontainer";
import MediaItem from "../../components/media-item/media-item";
import "./bookmarks.css";
import useDocumentTitle from "../../components/document-title/document-title";
import { createStructuredSelector } from "reselect";
import { selectBookmarks } from "../../redux/user/user.selector";
import { connect } from "react-redux";

const Bookmarks = ({ userBookmarks }) => {
    useDocumentTitle("User Bookmarks");

    return (
        <div className="all-items">
            <div className="main-header">Bookmarked Items</div>
            <FeaturedBox>
                <MyContainer>
                    <Row>
                        {userBookmarks.length < 1 ? (
                            <Col md={12} style={{ textAlign: "center" }}>
                                <h4>You don't have any items bookmarked yet</h4>
                            </Col>
                        ) : null}
                        {userBookmarks.map((item, i) => {
                            return (
                                <Col md={3}>
                                    <MediaItem key={i} item={item} />
                                </Col>
                            );
                        })}
                    </Row>
                </MyContainer>
            </FeaturedBox>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userBookmarks: selectBookmarks,
});

export default connect(mapStateToProps)(Bookmarks);
