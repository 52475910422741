import React from "react";
import "./custom-input.css";

const CustomInput = ({ name, bungedForm, ...otherProps }) => {
    return (
        <input
            name={name}
            className={`formfield ${bungedForm ? "bungedFormInput" : ""}`}
            {...otherProps}
        />
    );
};

export default CustomInput;
