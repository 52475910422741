import React from "react";
import { FiBookmark } from "react-icons/fi";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./media-item.css";
import { doBookmark } from "../../redux/user/user.actions";
import { createStructuredSelector } from "reselect";
import {
    selectBookmarks,
    selectUserDetails,
} from "../../redux/user/user.selector";

const MediaItem = ({ item, bookmarks, bookmark, user }) => {
    const history = useHistory();

    const bookmarkStatus = () => {
        const itemExists = bookmarks?.find(
            (bookmark) => bookmark.id === item.id
        );
        if (itemExists) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div
            className="media-item"
            style={{ backgroundImage: `url(${item?.preview_image})` }}
        >
            <div className="item-details">
                <div className="item-actions">
                    {user?.user_id ? (
                        <button
                            className={`media-action ${
                                bookmarkStatus() ? "active" : ""
                            }`}
                            onClick={() =>
                                bookmark(item, bookmarks, user.user_id)
                            }
                        >
                            <FiBookmark />
                        </button>
                    ) : null}
                    {/* <button className='media-action'><FiShare /></button> */}
                </div>
                <div
                    className="details"
                    onClick={() =>
                        history.push(
                            `/item/${item?.name.replaceAll(" ", "-")}-${
                                item?.id
                            }`
                        )
                    }
                >
                    <div className="name">{item?.name}</div>
                    <div className="author">Date added: {item?.date_added}</div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    bookmarks: selectBookmarks,
    user: selectUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
    bookmark: (item, bookmarks, user_id) =>
        dispatch(doBookmark(item, bookmarks, user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaItem);
